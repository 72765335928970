import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { stripeApiClient } from '../../../services/sharetribe/apiClients';
import { StripeAccount } from '../../../transactions/apiTypes';
import { isNotNil } from '../../../helpers/commonHelpers';
import { useCurrentUser } from '../../../user/hooks/useUser';

type Requirement = 'currently_due' | 'past_due' | 'eventually_due';

export const hasRequirements = (stripeAccountData: StripeAccount['stripeAccountData'] | undefined, requirements: Requirement[]) => {
    if (!stripeAccountData) {
        return false;
    }

    return requirements.some(
        (requirementType) =>
            stripeAccountData &&
            stripeAccountData.requirements &&
            Array.isArray(stripeAccountData.requirements[requirementType]) &&
            stripeAccountData.requirements[requirementType].length > 0,
    );
};

interface StripeAccountStatus {
    payoutsDisabled: boolean;
    hasCurrentRequirements: boolean; // user needs to fill information currently, e.g. upload a document or fill missing address details
    hasFutureRequirements: boolean; // user needs to fill information in the future, e.g. when they reach a certain threshold of transactions
    bankInformationMissing: boolean; // user has stripe account but no bank account added;
}

export const getStripeAccountStatus = (stripeAccountData: StripeAccount['stripeAccountData'] | undefined): StripeAccountStatus => {
    if (!stripeAccountData || !stripeAccountData.requirements) {
        return {
            hasCurrentRequirements: false,
            hasFutureRequirements: false,
            payoutsDisabled: false,
            bankInformationMissing: false,
        };
    }

    const hasFutureRequirements = hasRequirements(stripeAccountData, ['eventually_due']);
    const hasCurrentRequirements = hasRequirements(stripeAccountData, ['currently_due', 'past_due']);
    const payoutsDisabled = isNotNil(stripeAccountData.requirements.disabled_reason);
    const bankInformationMissing = hasCurrentRequirements && stripeAccountData?.requirements.currently_due[0] === 'external_account';

    return {
        payoutsDisabled,
        hasCurrentRequirements,
        hasFutureRequirements,
        bankInformationMissing,
    };
};

export type StripeAccountDataWithStatus = StripeAccount['stripeAccountData'] & StripeAccountStatus;

export const useStripeAccount = (): UseQueryResult<StripeAccountDataWithStatus> => {
    const { data: loggedInUser } = useCurrentUser();

    const stripeAccountId = loggedInUser?.stripeAccount?.stripeAccountId;

    const isValidLogin = !loggedInUser?.banned && !loggedInUser?.deleted;
    const enabled = !!stripeAccountId && isValidLogin;

    return useQuery(
        ['stripe-account'],
        async () => {
            const url = '/account';
            const { data } = await stripeApiClient.get<StripeAccount>(url);

            const status = getStripeAccountStatus(data.stripeAccountData);

            return {
                ...data.stripeAccountData,
                ...status,
            };
        },
        { enabled },
    );
};
