import React from 'react';
import { Box, Divider } from '@mui/material';
import { useParams } from 'react-router-dom';
import { ContentLoader } from '../../components/ContentLoader/ContentLoader';
import { useUser } from '../../user/hooks/useUser';
import { UserListings } from '../Wardrobe/UserListings';
import { ReactFCWithChildren } from '../../types/types';
import { ProfileSection } from '../../components/ProfileSection/ProfileSection';
import { useBreakpoint } from '../../hooks/useBreakpoint';
import { ResponsivePage } from '../../components/ResponsivePage/ResponsivePage';

export const UserView: ReactFCWithChildren = () => {
    const { id: userId } = useParams();
    const { status, data: user, error } = useUser(userId);

    const isDesktop = useBreakpoint('sm');
    const itemsPerRow = isDesktop ? 4 : 2;

    return (
        <ContentLoader status={status} style={{ height: 'unset' }} error={error}>
            {userId && user && (
                <>
                    <Box sx={{ width: '100%', padding: '16px' }}>
                        <ProfileSection userData={user} showBio enableFullScreen />
                        <Divider sx={{ paddingTop: '15px' }} />
                    </Box>

                    <UserListings userId={userId} itemsPerRow={itemsPerRow} />
                </>
            )}
        </ContentLoader>
    );
};
