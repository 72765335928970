import React, { useMemo } from 'react';
import { ProfileViewContainer } from './Wardrobe.components';
import { UserListings } from './UserListings';
import { User } from '../../types/apiTypes';
import { useCurrentUser } from '../../user/hooks/useUser';
import { ProfileSection } from '../../components/ProfileSection/ProfileSection';
import { Box } from '@mui/material';

import { useBreakpoint } from '../../hooks/useBreakpoint';
import { useSafeNavigate } from '../../hooks/useSafeNavigate';

const ProfileView = ({ user }: { user: User }): JSX.Element => {
    const { data: currentUser } = useCurrentUser();
    const navigate = useSafeNavigate();
    const userData = useMemo(() => user ?? currentUser, [currentUser, user]);

    const onNameClick = () => {
        navigate(`/user/${userData.id}`);
    };

    const isDesktop = useBreakpoint('sm');
    const itemsPerRow = isDesktop ? 3 : 2;

    return (
        <ProfileViewContainer>
            <Box sx={{ padding: '16px', width: '100%' }}>
                <ProfileSection userData={userData} onNameClick={onNameClick} showBio />
            </Box>

            <UserListings userId={userData.id} itemsPerRow={itemsPerRow} />
        </ProfileViewContainer>
    );
};

export default ProfileView;
