import { PathConfig } from './types';

/**
 * Defines the UI configuration of the Outlet based on the current path. If for example a route shows the topbar,
 * padding will be added to the top of the page to prevent the content from being hidden behind the topbar.
 */
export const pathConfig: PathConfig = [
    {
        path: '/',
        configuration: {
            label: 'home',
            hideTopbarPrefix: true,
            maxWidth: 1000,
            enablePullToRefresh: true,
        },
    },
    {
        path: '/onboarding',
        configuration: {
            animationOrder: 15,
            hideFooter: true,
            hideTopbar: true,
        },
    },
    {
        path: '/search',
        configuration: {
            hideFooter: true,
            hideTopbar: true,
        },
    },
    {
        path: '/listings',
        configuration: {
            label: 'listings',
            maxWidth: 1000,
            hideTopbarSuffix: true,
            disablePadding: { mobile: true, desktop: false },
            showFilterBar: true,
        },
    },
    {
        path: '/article/:id',
        configuration: { hideFooter: true, enablePullToRefresh: true },
    },
    {
        path: '/add-listing',
        configuration: {
            label: 'addListing',
            hideTopbar: { mobile: true, desktop: false },
            disablePadding: { mobile: true, desktop: false },
            hideFooter: true,
            hideTopbarSuffix: true,
            hidePayoutDisabledSnackbar: true,
            maxWidth: 800,
        },
    },
    {
        path: '/edit-listing/:id',
        configuration: {
            label: 'editListing',
            hideTopbar: { mobile: true, desktop: false },
            disablePadding: { mobile: true, desktop: false },
            hideFooter: true,
            hideTopbarSuffix: true,
            hidePayoutDisabledSnackbar: true,
            maxWidth: 800,
        },
    },

    {
        path: '/wardrobe',
        configuration: {
            animationOrder: 1,
            label: 'wardrobe',
            maxWidth: 900,
            enablePullToRefresh: true,
        },
    },
    { path: '/wardrobe/saved', configuration: { label: 'favorites' } },
    {
        path: '/transaction/:id',
        configuration: {
            label: 'transactionDetails',
            animationOrder: 7,
            hideTopbarSuffix: true,
            maxWidth: 900,
            enablePullToRefresh: true,
        },
    },
    { path: '/user/:id', configuration: { maxWidth: 900, label: 'userProfile' } },
    {
        path: '/listing/own/:id',
        configuration: {
            animationOrder: 1,
            hideTopbar: { mobile: true, desktop: false },
            hideTopbarPrefix: true,
            hideTopbarSuffix: true,
            maxWidth: 850,
            hideFooter: true,
        },
    },
    {
        path: '/listing/:id',
        configuration: {
            animationOrder: 4,
            hideTopbar: { mobile: true, desktop: false },
            hideTopbarSuffix: true,
            maxWidth: 850,
            hideFooter: true,
        },
    },
    {
        path: '/listing/:id/checkout',
        configuration: {
            label: 'checkout',
            animationOrder: 5,
            hideTopbarSuffix: true,
            maxWidth: 850,
            hideFooter: true,
        },
    },
    {
        path: '/listing/:id/complete',
        configuration: {
            label: 'orderComplete',
            animationOrder: 6,
            hideTopbarSuffix: true,
            maxWidth: 850,
            hideFooter: true,
        },
    },
    {
        path: '/login',
        configuration: {
            animationOrder: 13,
            hideTopbar: true,
            hideTopbarPrefix: true,
            hideTopbarSuffix: true,
            hideNavigation: true,
            maxWidth: 550,
            showPrivacyPolicyInFooter: true,
        },
    },
    {
        path: '/login/email',
        configuration: {
            animationOrder: 13,
            label: 'login',
            hideTopbar: true,
            hideTopbarPrefix: true,
            hideTopbarSuffix: true,
            hideNavigation: true,
            maxWidth: 550,
            showPrivacyPolicyInFooter: true,
        },
    },
    {
        path: '/signup',
        configuration: {
            animationOrder: 14,
            label: 'signUp',
            hideTopbarSuffix: true,
            hideSearchbar: true,
            hideNavigation: true,
            maxWidth: 650,
            hideFooter: true,
        },
    },
    {
        path: '/privacy-policy',
        configuration: {
            animationOrder: 16,
            label: 'privacyPolicy',
            hideTopbarSuffix: true,
            hideFooter: true,
            hideNavigation: true,
            hideSearchbar: true,
        },
    },
    {
        path: '/terms-of-service',
        configuration: {
            animationOrder: 16,
            label: 'termsOfService',
            hideTopbarSuffix: true,
            hideFooter: true,
            hideNavigation: true,
            hideSearchbar: true,
        },
    },
    {
        path: '/renter-protection',
        configuration: {
            animationOrder: 16,
            label: 'renterProtection',
            hideTopbar: { mobile: true, desktop: false },
            hideFooter: true,
            hideSearchbar: true,
        },
    },
    {
        path: '/fit-guarantee',
        configuration: {
            animationOrder: 16,
            label: 'fitGuarantee',
            hideTopbar: { mobile: true, desktop: false },
            hideFooter: true,
            hideSearchbar: true,
        },
    },
    {
        path: '/how-renting-works',
        configuration: {
            label: 'howItWorks',
            animationOrder: 99,
            maxWidth: 600,
            hideTopbar: { mobile: true, desktop: false },
            hideFooter: true,
            hideSearchbar: true,
        },
    },
    {
        path: '/verification-pending',
        configuration: {
            animationOrder: 16,
            label: 'verifyEmail',
            hideFooter: true,
            hideNavigation: true,
            hideSearchbar: true,
            hideTopbarSuffix: true,
            enablePullToRefresh: true,
        },
    },
    {
        path: '/verify-email',
        configuration: {
            animationOrder: 17,
            label: 'verifyEmail',
            hideFooter: true,
            hideNavigation: true,
            hideTopbarSuffix: true,
            hideSearchbar: true,
        },
    },
    { path: '/reset-password', configuration: { hideFooter: true, hideNavigation: true, hideSearchbar: true, hideTopbarSuffix: true } },
    {
        path: '/reset-password/request',
        configuration: {
            animationOrder: 18,
            hideTopbarSuffix: true,
            label: 'passwordResetRequest',
            hideFooter: true,
            hideNavigation: true,
            hideSearchbar: true,
        },
    },
    {
        path: '/reset-password/reset',
        configuration: {
            animationOrder: 19,
            hideTopbarSuffix: true,
            label: 'passwordReset',
            hideFooter: true,
            hideNavigation: true,
            hideSearchbar: true,
        },
    },
    {
        path: '/create-password',
        configuration: {
            animationOrder: 19,
            hideTopbarSuffix: true,
            label: 'createPassword',
            hideFooter: true,
            hideNavigation: true,
            hideSearchbar: true,
        },
    },
    { path: '/messages', configuration: { label: 'messages', enablePullToRefresh: true } },
    {
        path: '/identify',
        configuration: {
            label: 'identification',
            animationOrder: 19,
            hideFooter: true,
            hideTopbar: true,
            hideNavigation: true,
            maxWidth: 500,
        },
    },
    {
        path: '/identify/:returnType',
        configuration: {
            label: 'identification',
            animationOrder: 19,
            hideFooter: true,
            hideTopbar: true,
            hideNavigation: true,
            maxWidth: 500,
        },
    },
    { path: '/profile/personal-information', configuration: { label: 'personalInformation', animationOrder: 99, maxWidth: 550 } },
    { path: '/profile/credits', configuration: { label: 'credits', animationOrder: 99, maxWidth: 550 } },
    {
        path: '/profile/payments-and-payouts',
        configuration: { label: 'paymentsAndPayouts', animationOrder: 99, maxWidth: 550, hidePayoutDisabledSnackbar: true },
    },
    {
        path: '/profile/payments-and-payouts/success',
        configuration: {
            label: 'paymentsAndPayouts',
            animationOrder: 99,
            maxWidth: 550,
            hideTopbar: true,
            hideTopbarPrefix: true,
            hideFooter: true,
            hidePayoutDisabledSnackbar: true,
        },
    },
    { path: '/profile/settings', configuration: { label: 'settings', animationOrder: 99, maxWidth: 550 } },
    { path: '/profile/notifications', configuration: { label: 'notifications', animationOrder: 99, maxWidth: 550 } },
    { path: '/profile/contact-us', configuration: { label: 'contactUs', animationOrder: 99, maxWidth: 550 } },
    {
        path: '/profile/how-renting-works',
        configuration: {
            label: 'howItWorks',
            animationOrder: 99,
            maxWidth: 600,
            hideTopbar: { mobile: true, desktop: false },
            hideFooter: true,
            hideSearchbar: true,
        },
    },
    { path: '/profile/terms-of-service', configuration: { label: 'termsOfService', animationOrder: 99 } },
    {
        path: '/profile/delete-account',
        configuration: {
            animationOrder: 100,
            label: 'deleteAccount',
            hideTopbarSuffix: true,
            hideNavigation: true,
            hideSearchbar: true,
        },
    },
    // TODO: Top level routes (routes which have children, e.g /profile) should not have a configuration because it limits the ability to e.g hide the topbar and footer in children.
    {
        path: '/profile',
        configuration: { animationOrder: 1, label: 'profile', maxWidth: 550 },
    },
];
