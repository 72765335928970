import { Error } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Skeleton, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useCreateStripeAccount } from '../hooks/useCreateStripeAccount';
import { useCreateStripeAccountLink } from '../hooks/useCreateStripeAccountLink';
import { useStripeAccount } from '../hooks/useStripeAccount';
import { useUpdateStripeAccount } from '../hooks/useUpdateStripeAccount';
import { RedirectToStripe } from '../Stripe/RedirectToStripe';
import { StripeConnectForm } from '../Stripe/StripeConnectForm';
import { SettingsContainer } from '../UserProfile.components';
import { useCurrentUser } from '../../../user/hooks/useUser';
import { StripeVerifiedLogo } from '../Stripe/StripeVerifiedLogo';
import { ContentLoader } from '../../../components/ContentLoader/ContentLoader';
import { useTranslation } from 'react-i18next';
import { useSafeNavigate } from '../../../hooks/useSafeNavigate';
import { StripeAccountAlertMaybe } from '../../../components/StripeAccountAlert/StripeAccountAlertMaybe';

const StripeConnectSkeleton = () => (
    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: '4px' }}>
        <Skeleton width="100%" height={30} variant="rounded" />
        <Skeleton width="100%" height={40} variant="rounded" />
        <Skeleton width="60%" height={40} variant="text" sx={{ marginTop: '30px' }} />
        <div style={{ display: 'flex', width: '100%', gap: '5px' }}>
            <Skeleton style={{ flexGrow: 1 }} height={50} variant="rounded" />
            <Skeleton style={{ flexGrow: 1 }} height={50} variant="rounded" />
        </div>

        <Skeleton width="100%" height={40} variant="rounded" />
    </Box>
);

export const PaymentsAndPayouts: React.FC = () => {
    const navigate = useSafeNavigate();
    const { t } = useTranslation();
    const { returnType } = useParams();
    const [editingPayoutDetails, setEditingPayoutDetails] = useState(false);
    const [isRedirecting, setIsRedirecting] = useState(false);
    const [accountLinkError, setAccountLinkError] = useState(false);
    const { data: user } = useCurrentUser();

    const stripeConnected = user?.stripeConnected;

    const { data: stripeAccountData, isInitialLoading } = useStripeAccount();

    const { mutate: startStripeOnboarding, isLoading: accountLinkInProgress } = useCreateStripeAccountLink('/profile/payments-and-payouts', () => {
        setAccountLinkError(true);
        setIsRedirecting(false);
    });

    const handleStartOnboarding = () => {
        if (isRedirecting) {
            return;
        }
        setIsRedirecting(true);
        startStripeOnboarding('custom_account_verification');
    };

    const handleStripeAccountUpdateSuccess = () => {
        setEditingPayoutDetails(false);
    };

    const { mutate: createStripeAccount, isLoading: stripeAccountInProgress } = useCreateStripeAccount(handleStartOnboarding);
    const { mutate: updateStripeAccount, isLoading: stripeAccountUpdateInProgress } = useUpdateStripeAccount(handleStripeAccountUpdateSuccess);

    const returnedNormallyFromStripe = returnType === 'success';
    const returnedAbnormallyFromStripe = returnType === 'error';

    const { hasCurrentRequirements, bankInformationMissing } = stripeAccountData || {};

    return (
        <SettingsContainer>
            <ContentLoader status={isInitialLoading ? 'loading' : 'idle'} skeleton={<StripeConnectSkeleton />}>
                <>
                    {stripeConnected ? (
                        <>
                            {!returnedAbnormallyFromStripe && !returnedNormallyFromStripe && !hasCurrentRequirements && (
                                <div
                                    style={{
                                        display: 'flex',
                                        gap: '25px',
                                        width: '100%',
                                        marginTop: '20px',
                                        flexDirection: 'column',
                                    }}
                                >
                                    {editingPayoutDetails ? (
                                        <>
                                            <StripeConnectForm isLoading={stripeAccountUpdateInProgress} connectStripeFn={updateStripeAccount} />
                                        </>
                                    ) : (
                                        <>
                                            <StripeVerifiedLogo />

                                            <Button fullWidth variant="outlined" onClick={() => setEditingPayoutDetails(true)} sx={{ mt: 3 }}>
                                                {t('editPayoutDetails')}
                                            </Button>
                                        </>
                                    )}
                                </div>
                            )}
                            {returnedAbnormallyFromStripe && !accountLinkError && <RedirectToStripe redirectFn={handleStartOnboarding} />}
                            {returnedAbnormallyFromStripe && accountLinkError && (
                                <div style={{ width: '100%', textAlign: 'center' }}>
                                    <Error />
                                    <Typography>{t('somethingWentWrongOurEnd')}</Typography>
                                </div>
                            )}
                            {returnedNormallyFromStripe && !hasCurrentRequirements && (
                                <div
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        flexDirection: 'column',
                                        overflowY: 'auto',
                                    }}
                                >
                                    <StripeVerifiedLogo />

                                    <Typography variant="body1" sx={{ mb: 4, mt: 10, textAlign: 'center' }}>
                                        {t('timeToAdd')}
                                    </Typography>

                                    <Button fullWidth variant="contained" onClick={() => navigate('/add-listing', { replace: true })}>
                                        {t('addNewItems')}
                                    </Button>
                                    <Button fullWidth variant="outlined" onClick={() => navigate('/profile', { replace: true })} sx={{ mt: 1 }}>
                                        {t('backToSettings')}
                                    </Button>
                                </div>
                            )}
                            {!bankInformationMissing && !returnedAbnormallyFromStripe && hasCurrentRequirements && (
                                <Box sx={{ width: '100%' }}>
                                    <StripeAccountAlertMaybe stripeConnected={stripeConnected} stripeAccount={stripeAccountData} hideButton />

                                    <LoadingButton
                                        fullWidth
                                        loading={accountLinkInProgress || isRedirecting}
                                        onClick={handleStartOnboarding}
                                        variant="contained"
                                    >
                                        {t('verifyMyIdentity')}
                                    </LoadingButton>
                                </Box>
                            )}
                        </>
                    ) : (
                        <div style={{ display: 'flex', gap: '20px', flexDirection: 'column' }}>
                            <>
                                <Typography variant="h6" color="primary" sx={{ fontWeight: 'bold' }}>
                                    {t('planToBeALender')}
                                </Typography>
                                <Typography variant="body1" sx={{ mb: 2 }}>
                                    {t('lenderGuidanceText')}
                                </Typography>
                                <StripeConnectForm
                                    isLoading={stripeAccountInProgress || accountLinkInProgress || isRedirecting}
                                    connectStripeFn={createStripeAccount}
                                />
                            </>
                        </div>
                    )}
                </>
            </ContentLoader>
        </SettingsContainer>
    );
};
