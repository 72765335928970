import React from 'react';
import { Button, IconButton, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { VerifiedUser } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

interface UsernameLabelProps {
    user: { displayName: string; banned: boolean; deleted: boolean; id: string; identityVerified?: boolean };
    onClick: (userId: string) => void;
}

export const UsernameLabel: React.FC<UsernameLabelProps> = ({ user, onClick }) => {
    const { t } = useTranslation();
    const theme = useTheme();

    if (user.deleted) {
        return (
            <Typography variant="body2" sx={{ color: theme.palette.error.main }}>
                DELETED
            </Typography>
        );
    }

    if (user.banned) {
        return (
            <Typography variant="body2" sx={{ textDecoration: 'line-through' }}>
                {user.displayName}
            </Typography>
        );
    }

    return (
        <Stack direction="row" alignItems="center">
            <Button variant="text" sx={{ minWidth: 0, textTransform: 'capitalize', padding: 0 }} onClick={() => onClick(user.id)}>
                {user.displayName}
            </Button>
            {user.identityVerified && (
                <Tooltip title={t('hasCompletedSCA')}>
                    <IconButton sx={{ ml: '8px', p: 0 }}>
                        <VerifiedUser fontSize="small" color="primary" />
                    </IconButton>
                </Tooltip>
            )}
        </Stack>
    );
};
