import { Alert, Collapse, Grow, Snackbar } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useStripeAccount } from '../../views/UserProfile/hooks/useStripeAccount';
import { Trans, useTranslation } from 'react-i18next';
import { StyledLinkColor } from '../Styled/Styled.components';
import { Location, matchPath } from 'react-router-dom';
import { useAppSelector } from '../../store/hooks';
import { selectToken } from '../../store/userReducer';
import { getPathConfig } from '../../routes/helpers';

export const StripeAccountAlertToast: React.FC<{ location: Location }> = ({ location }) => {
    const [closedByUser, setClosedByUser] = useState(false);

    const { t } = useTranslation();
    const { data: stripeAccount } = useStripeAccount();

    const { payoutsDisabled } = stripeAccount || {};
    const token = useAppSelector(selectToken);
    const { hidePayoutDisabledSnackbar } = getPathConfig(location.pathname);

    const snackbarOpen = !!token && !closedByUser && payoutsDisabled && !hidePayoutDisabledSnackbar;

    useEffect(() => {
        if (closedByUser) {
            setClosedByUser(false);
        }
    }, [token]);

    return (
        <Snackbar open={snackbarOpen} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} TransitionComponent={Grow}>
            <Alert severity="error" sx={{ mb: 3, borderRadius: '10px' }} onClose={() => setClosedByUser(true)}>
                <Trans
                    i18nKey="stripePayoutsDisabledSnackbar"
                    components={{
                        payoutLink: <StyledLinkColor to="/profile/payments-and-payouts">{t('paymentsAndPayouts')}</StyledLinkColor>,
                    }}
                />
            </Alert>
        </Snackbar>
    );
};
