import React from 'react';
import { CssBaseline, useTheme } from '@mui/material';
import { Outlet, useLocation } from 'react-router-dom';
import { MobileOutlet } from '../../MobileOutlet';
import { getTransitionVariant } from '../../animations/helpers';
import { AuthProvider } from '../../context/auth';
import { ChatProvider } from '../../context/chat';
import { ConfirmProvider } from '../../context/confirm';
import { useBreakpoint } from '../../hooks/useBreakpoint';
import { usePrevious } from '../../hooks/usePrevious';
import PushNotificationProvider from '../../pushNotifications/PushNotificationProvider';
import { TopBar } from './Topbar/Topbar';
import { StyledToastContainer } from './rootContainer.components';
import { ErrorBoundary } from '../ErrorBoundary/ErrorBoundary';
import { Footer } from './Footer/Footer';
import { OnboardingModalMaybe } from '../OnboardingModalMaybe/OnboardingModalMaybe';
import { SocketProvider } from '../../context/socket';
import { Effects } from './Effects/Effects';
import { Alerts } from './Alerts/Alerts';
import { FilterDrawer } from '../../views/Listings/Search/Filters/FilterDrawer';
import { AdminActionsProvider } from '../../context/admin';

export const RootContainerOutlet: React.FC = () => {
    const theme = useTheme();
    const location = useLocation();
    const isDesktop = useBreakpoint('sm');
    const previousLocation = usePrevious(location.pathname);
    const transitionVariant = getTransitionVariant(location.pathname, previousLocation || '');

    return (
        <ErrorBoundary>
            <SocketProvider>
                <ChatProvider>
                    <AdminActionsProvider>
                        <PushNotificationProvider>
                            <AuthProvider>
                                <ConfirmProvider>
                                    <CssBaseline />
                                    <Effects />
                                    <Alerts />

                                    <TopBar />

                                    <OnboardingModalMaybe location={location} />

                                    <ErrorBoundary>
                                        {isDesktop ? <Outlet /> : <MobileOutlet location={location} transitionVariant={transitionVariant} />}
                                    </ErrorBoundary>

                                    <Footer isDesktop={isDesktop} />

                                    <StyledToastContainer
                                        backgroundColor={theme.palette.background.paper}
                                        textColor={theme.palette.text.primary}
                                        position="bottom-center"
                                        autoClose={5000}
                                        newestOnTop={false}
                                        closeOnClick
                                        rtl={false}
                                        hideProgressBar
                                        draggable
                                        pauseOnHover
                                    />

                                    <FilterDrawer />
                                </ConfirmProvider>
                            </AuthProvider>
                        </PushNotificationProvider>
                    </AdminActionsProvider>
                </ChatProvider>
            </SocketProvider>
        </ErrorBoundary>
    );
};
