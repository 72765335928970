import { Loader } from '@mantine/core';
import { Edit, Info, InfoOutlined } from '@mui/icons-material';
import { Skeleton, IconButton, Typography, Divider, Box, CircularProgress, FormControlLabel, ButtonBase } from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';
import _ from 'lodash';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import WoltLogo from '../../assets/images/wolt-logo.png';
import { ProfileSection } from '../../components/ProfileSection/ProfileSection';
import { StyledHeader } from '../../components/Styled/Styled.components';
import { minimumRentalLength } from '../../constants';
import { formatPrice, getListingTitle } from '../../helpers/commonHelpers';
import { DAYS_IN_WEEK, DAYS_IN_MONTH } from '../AddListing/PricingModelForm.helpers';
import ProfileView from '../Wardrobe/ProfileView';
import { PublicDataSection } from './PublicDataSection';
import { ListingDataContainer, ItemDescriptionText } from './ViewListing.components';
import { isExtendedListing, isListingPreview } from './ViewListing.helpers';
import { ExtendedListing, Listing, ListingPreview } from '../../types/apiTypes';
import { ReactFCWithChildren } from '../../types/types';
import { Modal } from '../../components/Modal/Modal';
import { useSafeNavigate } from '../../hooks/useSafeNavigate';
import { RenterProtectionSection } from './RenterProtectionSection';
import { HowRentingWorksSection } from './HowRentingWorks';
import { DeliveryOptionsInformationDrawer } from './DeliveryOptionsInformationDrawer';
import { DeliveryOptionsSection } from './DeliveryOptionsSection';
import { CancellationPolicySection } from './CancellationPolicySection';
import { TryonSection } from './TryonSection';

const FadeInOutContainer: ReactFCWithChildren = ({ children }) => (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.2 }}>
        {children}
    </motion.div>
);

interface ListingPublicDataSectionProps {
    listing: Listing | ExtendedListing | ListingPreview;
    showProfile?: boolean;
    setShowProfile?: (val: boolean) => void;
    isOwnListing: boolean;
}

export const ListingPublicDataSection: React.FC<ListingPublicDataSectionProps> = ({ listing, setShowProfile, showProfile, isOwnListing }) => {
    const [woltInfoDrawerOpen, setWoltInfoDrawerOpen] = React.useState(false);
    const { t } = useTranslation();
    const { description } = listing;
    const navigate = useSafeNavigate();
    const extendedListing = isExtendedListing(listing);
    const listingPreview = isListingPreview(listing);

    const title = getListingTitle(listing, t);

    return (
        <ListingDataContainer>
            {extendedListing || listingPreview ? (
                <ProfileSection
                    allowEditing={!listingPreview}
                    userData={listing.author}
                    pictureSize="small"
                    onNameClick={() => setShowProfile && setShowProfile(true)}
                    listingId={extendedListing ? listing.id : undefined}
                />
            ) : (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    <Skeleton variant="circular" height={40} width={40} />
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Skeleton variant="text" width={100} />
                        <Skeleton variant="text" width={80} height={10} />
                    </div>
                </Box>
            )}

            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px', marginTop: '8px' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', maxWidth: isOwnListing ? '90%' : '100%' }}>
                    <StyledHeader variant="h6" sx={{ width: '100%' }}>
                        {title}
                    </StyledHeader>

                    <DeliveryOptionsInformationDrawer open={woltInfoDrawerOpen} onClose={() => setWoltInfoDrawerOpen(false)} />
                </Box>

                {isOwnListing && (
                    <IconButton
                        onClick={() => navigate(`/edit-listing/${_.get(listing, 'id', '')}`)}
                        sx={{ borderRadius: '10px', padding: '4px 8px' }}
                    >
                        <Edit />
                    </IconButton>
                )}
            </Box>

            <AnimatePresence mode="wait">
                {extendedListing || listingPreview ? (
                    <FadeInOutContainer key="extended-data">
                        {listing.price && (
                            <div style={{ marginBottom: '10px' }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Typography variant="subtitle2" fontWeight="bold" textTransform="capitalize">
                                        {t('day')}
                                    </Typography>
                                    <Typography>{formatPrice(listing.price)}</Typography>
                                </div>

                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <span style={{ display: 'flex' }}>
                                        <Typography variant="subtitle2" fontWeight="bold" textTransform="capitalize">
                                            {t('week')}
                                        </Typography>
                                        &nbsp;
                                        <Typography variant="subtitle2">{`(7 ${t('days')})`}</Typography>
                                    </span>
                                    <Typography>
                                        {listing.publicData?.pricing?.weekly
                                            ? formatPrice({ amount: listing.publicData.pricing.weekly, currency: listing.price.currency })
                                            : formatPrice({ amount: listing.price.amount * DAYS_IN_WEEK, currency: listing.price.currency })}
                                    </Typography>
                                </div>

                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <span style={{ display: 'flex' }}>
                                        <Typography variant="subtitle2" fontWeight="bold" textTransform="capitalize">
                                            {t('month')}
                                        </Typography>
                                        &nbsp;
                                        <Typography variant="subtitle2">{`(28 ${t('days')})`}</Typography>
                                    </span>
                                    <Typography>
                                        {listing.publicData?.pricing?.monthly
                                            ? formatPrice({ amount: listing.publicData.pricing.monthly, currency: listing.price.currency })
                                            : formatPrice({ amount: listing.price.amount * DAYS_IN_MONTH, currency: listing.price.currency })}
                                    </Typography>
                                </div>
                                <Typography sx={{ opacity: 0.7 }} variant="caption">
                                    {t('minimumDaysRental', { amount: minimumRentalLength })}
                                </Typography>
                            </div>
                        )}

                        <Divider sx={{ my: 1 }} />

                        {extendedListing && (
                            <>
                                <TryonSection listing={listing} isOwnListing={isOwnListing} />
                                <DeliveryOptionsSection listing={listing} onClick={() => setWoltInfoDrawerOpen(true)} />
                            </>
                        )}

                        <RenterProtectionSection />

                        <CancellationPolicySection />

                        <Divider sx={{ ...(description && { my: 1 }) }} />

                        <ItemDescriptionText>{description}</ItemDescriptionText>

                        {listing.publicData && (
                            <Box sx={{ mt: 4 }}>
                                <PublicDataSection publicData={listing.publicData} />
                            </Box>
                        )}

                        <Divider sx={{ mt: 2 }} />

                        {extendedListing && <HowRentingWorksSection listing={listing} />}

                        <Modal
                            open={!!showProfile}
                            variant="lg"
                            onClose={() => setShowProfile && setShowProfile(false)}
                            modalStyle={{ maxWidth: '430px' }}
                            disableFocusTrap
                        >
                            <ProfileView user={listing.author} />
                        </Modal>
                    </FadeInOutContainer>
                ) : (
                    <FadeInOutContainer key="loader">
                        <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center' }}>
                            <CircularProgress size="2rem" />
                        </div>
                    </FadeInOutContainer>
                )}
            </AnimatePresence>
        </ListingDataContainer>
    );
};
