import React from 'react';
import { StripeAccountDataWithStatus } from '../../views/UserProfile/hooks/useStripeAccount';
import { useTranslation } from 'react-i18next';
import { Alert, Button } from '@mui/material';

interface StripeAccountAlertMaybeProps {
    stripeConnected: boolean | undefined;
    stripeAccount: StripeAccountDataWithStatus | undefined;
    hideButton?: boolean;
    onClick?: () => void;
}

export const StripeAccountAlertMaybe: React.FC<StripeAccountAlertMaybeProps> = ({ stripeConnected, stripeAccount, hideButton, onClick }) => {
    const { t } = useTranslation();

    if (stripeConnected === undefined && stripeAccount === undefined) {
        return null;
    }

    const { payoutsDisabled, hasCurrentRequirements } = stripeAccount || {};

    if (!stripeConnected || (hasCurrentRequirements && !payoutsDisabled)) {
        return (
            <Alert severity="error" sx={{ mb: 3, borderRadius: '10px' }}>
                {t('stripeNotConnectedError')}

                {!hideButton && (
                    <Button fullWidth variant="text" sx={{ textTransform: 'none', px: 0, pt: 2, justifyContent: 'start' }} onClick={onClick}>
                        {t('stripeNotConnectedButtonTitle')}
                    </Button>
                )}
            </Alert>
        );
    }

    if (payoutsDisabled) {
        return (
            <Alert severity="error" sx={{ mb: 3, borderRadius: '10px' }}>
                {t('stripePayoutsDisabledError')}{' '}
                {!hideButton && (
                    <Button fullWidth variant="text" sx={{ textTransform: 'none', px: 0, pt: 2, justifyContent: 'start' }} onClick={onClick}>
                        {t('verifyMyIdentity')}
                    </Button>
                )}
            </Alert>
        );
    }

    return null;
};
