import React from 'react';
import { OwnTransaction, BookingState } from '../../../transactions/apiTypes';
import { ReactFCWithChildren } from '../../../types/types';
import { DisplayableTransaction } from './DisplayableTransaction';
import { ActionableTransaction } from './ActionableTransaction';
import { OfficeConfigurationMethods } from '../../../queries/useDeliveryTiming';

interface TransactionCardProps {
    transactionData: OwnTransaction;
    officeConfigurationMethods: OfficeConfigurationMethods;
    onClickUser: (userId: string) => void;
    onAction: (action: 'decline' | 'confirm', transaction: OwnTransaction) => void;
    type: 'sale' | 'order';
}

export const TransactionCard: ReactFCWithChildren<TransactionCardProps> = ({
    transactionData,
    type,
    onAction,
    onClickUser,
    officeConfigurationMethods,
}) => {
    const actionableStates: BookingState[] = ['pending', 'proposed'];
    const bookingState = transactionData.booking.attributes.state;
    const userNeedsToConfirm = type === 'sale';
    const isActionable =
        userNeedsToConfirm && actionableStates.includes(bookingState) && !transactionData.customer.banned && !transactionData.customer.deleted;

    return (
        <>
            {isActionable ? (
                <ActionableTransaction
                    officeConfigurationMethods={officeConfigurationMethods}
                    transactionData={transactionData}
                    type={type}
                    onAction={onAction}
                    onClickUser={onClickUser}
                />
            ) : (
                <DisplayableTransaction
                    officeConfigurationMethods={officeConfigurationMethods}
                    transactionData={transactionData}
                    type={type}
                    onClickUser={onClickUser}
                />
            )}
        </>
    );
};
