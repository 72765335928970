/* eslint-disable no-nested-ternary */
import { Transition, Variants } from 'framer-motion';
import { RouteTransitionVariant } from '../types/types';

export const routeVariants: Variants = {
    initial: (direction: 'left' | 'right' | 'static') => ({
        x: direction === 'left' ? -80 : direction === 'right' ? 80 : 0,
        opacity: direction === 'static' ? 1 : 0,
    }),
    in: (direction: 'left' | 'right' | 'static') => ({
        x: 0,
        opacity: 1,
        transition: {
            duration: direction === 'static' ? 0.35 : 0.45,
            ease: [0.5, -0.05, 0.01, 0.99],
        },
    }),
    out: (direction: 'left' | 'right' | 'static') => ({
        x: direction === 'left' ? 55 : direction === 'right' ? -55 : 0,
        opacity: direction === 'static' ? 1 : 0,
        transition: {
            ease: 'easeInOut',
            duration: 0.35,
        },
    }),
};

export const childRouteVariants: Variants = {
    initial: (variant: RouteTransitionVariant) => ({ opacity: 0, x: variant === 'left' ? -20 : variant === 'right' ? 20 : 0 }),
    in: {
        opacity: 1,
        x: 0,
        transition: {
            delay: 0.1,
            type: 'spring',
            stiffness: 280,
            damping: 35,
            bounce: 0,
        },
    },
    out: (variant: RouteTransitionVariant) => ({
        opacity: 0,
        x: variant === 'left' ? -20 : variant === 'right' ? 20 : 0,
        transition: {
            type: 'spring',
            stiffness: 260,
            damping: 22,
            bounce: 0,
        },
    }),
};

export const fadeInOut: Variants = {
    initial: { opacity: 0 },
    in: (delay: number) => ({ opacity: 1, transition: { delay } }),
    out: {
        opacity: 0,
    },
};

export const fadeInOutScale: Variants = {
    initial: { opacity: 0, scale: 0.97 },
    in: (delay: number) => ({ opacity: 1, scale: 1, transition: { delay } }),
    out: {
        opacity: 0,
        scale: 0.97,
    },
};

export const stationary: Variants = {
    initial: { x: 0 },
    in: { x: 0 },
    out: {
        x: 0,
    },
};

export const scale: Variants = {
    initial: { scale: 0 },
    in: { scale: 1 },
    out: { scale: 0 },
};

export const opacity: Variants = {
    in: { opacity: 1 },
};

export const fadeInScale: Variants = {
    initial: { opacity: 0, scale: 1.3 },
    in: { opacity: 1, scale: 1 },
    out: {
        opacity: 0,
        scale: 0.7,
    },
};

export const fadeInScaleZoom: Variants = {
    initial: { opacity: 0, scale: 0.85 },
    in: { opacity: 1, scale: 1 },
    out: {
        opacity: 0,
        scale: 0.7,
    },
};

export const fadeIn: Variants = {
    initial: { opacity: 0 },
    in: { opacity: 1 },
};

export const swap: Variants = {
    initial: {
        scale: 0,
        opacity: 0,
    },
    in: {
        scale: 1,
        opacity: 1,
    },
    out: {
        scale: 0,
        opacity: 0,
    },
};

export const swapVertical: Variants = {
    initial: ({ dir, height }) => ({
        y: dir === 'up' ? height : -height,
        opacity: 0,
    }),
    in: {
        y: 0,
        opacity: 1,
    },
    out: ({ dir, height }) => ({
        y: dir === 'up' ? height : -height,
        opacity: 0,
    }),
};

export const grow: Variants = {
    initial: {
        height: 0,
        opacity: 0,
    },
    in: {
        height: 'auto',
        opacity: 1,
    },
    out: {
        height: 0,
        opacity: 0,
    },
};

export const fadeUp: Variants = {
    initial: {
        y: 30,
        opacity: 0,
    },
    in: {
        y: 0,
        opacity: 1,
    },
    out: {
        y: 30,
        opacity: 0,
    },
};

export const containerVariants: Variants = {
    hidden: { opacity: 0 },
    show: {
        opacity: 1,
    },
};

export const containerVariantsStagger: Variants = {
    hidden: { opacity: 0 },
    show: (args) => ({
        opacity: 1,
        transition: {
            staggerChildren: args.stagger,
            delayChildren: args.delay,
        },
    }),
};

export const itemVariants: Variants = {
    hidden: (scale = 0.5) => ({ opacity: 0, scale }),
    show: { opacity: 1, scale: 1 },
};

export const itemVariantsB: Variants = {
    hidden: (scale = 0.5) => ({ opacity: 0, scale: 0 }),
    show: { opacity: 1, scale: [0, 1] },
};

export const itemVariantsOpacity: Variants = {
    hidden: { opacity: 0 },
    show: { opacity: 1 },
};

export const itemVariantsScale: Variants = {
    hidden: { opacity: 0, scale: 0.8 },
    show: { opacity: 1, scale: 1 },
};

export const itemVariantsFadeLeft: Variants = {
    hidden: { opacity: 0, x: -70, scale: 0 },
    show: { opacity: 1, x: 0, scale: 1 },
};

export const itemVariantsFadeRight: Variants = {
    hidden: { opacity: 0, x: 70 },
    show: { opacity: 1, x: 0 },
};

export const itemVariantsFadeDown: Variants = {
    hidden: { opacity: 0, y: -70 },
    show: { opacity: 1, y: 0 },
};

// https://easings.net/#easeInOutCirc
export const easeInOutCirc = (x: number): number => (x < 0.5 ? (1 - Math.sqrt(1 - (2 * x) ** 2)) / 2 : (Math.sqrt(1 - (-2 * x + 2) ** 2) + 1) / 2);

// https://easings.net/#easeInOutBack
export const easeInOutBack = (x: number): number => {
    const c1 = 1.70158;
    const c2 = c1 * 1.525;

    return x < 0.5 ? ((2 * x) ** 2 * ((c2 + 1) * 2 * x - c2)) / 2 : ((2 * x - 2) ** 2 * ((c2 + 1) * (x * 2 - 2) + c2) + 2) / 2;
};

export const pageTransition: Transition = {
    duration: 0.2,
    ease: easeInOutCirc,
};
