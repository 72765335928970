import { useQuery } from '@tanstack/react-query';
import { AppUpdate, AppUpdateAvailability } from '@capawesome/capacitor-app-update';
import { sendToSentry, sleep } from '../../helpers/commonHelpers';
import { Capacitor } from '@capacitor/core';

const checkNewVersionAvailable = async () => {
    try {
        const result = await AppUpdate.getAppUpdateInfo({ country: 'FI' });

        await sleep(3000);

        return result.updateAvailability === AppUpdateAvailability.UPDATE_AVAILABLE;
    } catch (e) {
        sendToSentry(e);
    }
};

export const useCheckNewVersionAvailable = () => {
    const enabled = Capacitor.isNativePlatform();

    return useQuery(['new-version-available'], checkNewVersionAvailable, { staleTime: 1000 * 60 * 15, enabled });
};
