import React from 'react';
import { User } from '../../types/apiTypes';
import { AddButtonIcon } from '../AddImage/AddImagesButton.components';
import { ProfileImageContainer, ProfileTextContainer, ProfileRowContainer, Image } from './ProfileSection.components';
import { UsernameLabel } from '../../views/Wardrobe/TransactionCards/UsernameLabel';
import { AddProfilePicture } from '../AddProfilePicture/AddProfilePicture';
import { MessageIconButton } from '../MessageIconButton/MessageIconButton';
import FullScreenImage from '../FullScreenImage/FullScreenImage';
import { OpenUserAdminActionProps } from '../../context/admin';
import { AdminDrawerButton } from '../Admin/AdminDrawerButton';

const sizes = { small: { width: '40px', height: '40px' }, medium: { width: '55px', height: '55px' } };

interface ProfileRowInfoProps {
    userData: User;
    isOwnUser: boolean;
    pictureSize: 'small' | 'medium';
    allowEditing: boolean;
    enableFullScreen?: boolean;
    handleNameClick: () => void;
    handleChatIconClick: () => void;
}

export const ProfileRowInfo: React.FC<ProfileRowInfoProps> = ({
    isOwnUser,
    allowEditing,
    userData,
    handleNameClick,
    handleChatIconClick,
    pictureSize = 'medium',
    enableFullScreen = false,
}) => {
    const allowProfileImageEditing = allowEditing && isOwnUser;
    const showFullScreenImage = userData.profileImage && enableFullScreen && !allowProfileImageEditing;

    const renderProfileImage = () => {
        if (allowProfileImageEditing) {
            return <AddProfilePicture imgUrl={userData.profileImage} />;
        }

        if (showFullScreenImage) {
            return <FullScreenImage src={userData.profileImage!} />;
        }

        if (userData.profileImage) {
            return <Image onClick={handleNameClick} src={userData.profileImage} alt="user pic" />;
        }

        return <AddButtonIcon />;
    };

    const adminDrawerProps: OpenUserAdminActionProps = { target: 'user', user: userData };

    return (
        <ProfileRowContainer>
            <ProfileImageContainer sx={{ ...sizes[pictureSize] }}>{renderProfileImage()}</ProfileImageContainer>

            <ProfileTextContainer>
                <UsernameLabel
                    onClick={handleNameClick}
                    user={{
                        displayName: userData.profile.displayName,
                        banned: userData.banned,
                        deleted: userData.deleted,
                        id: userData.id,
                        identityVerified: userData.profile.publicData?.identityVerified,
                    }}
                />
            </ProfileTextContainer>

            <AdminDrawerButton props={adminDrawerProps} />

            {!isOwnUser && (
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <MessageIconButton onClick={handleChatIconClick} />
                </div>
            )}
        </ProfileRowContainer>
    );
};
